<template>
  <div>
    <b-form-group
        :description="description"
        :label="label"
        :label-for="id"
    >
      <b-form-input
          v-model="internalValue"
          :type="type"
          :class="classInput"
          :id="id"
          :placeholder="placeholder"
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "Field",

  props: {
    value: String,
    label: String,
    description: String,
    type: String,
    classInput: String,
    id: String,
    placeholder: String
  },

  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>