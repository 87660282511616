<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="div_logo col-sm-6">
          <a href="https://www.reseau-colibri.fr">
            <img width="100" src="../assets/logo.png" alt="logo"/>
          </a>
        </div>
        <div class="col-sm-6 col_right">
          <p class="titre_footer">{{ $t('footer.links') }}</p>
          <div class="liens_footer">
            <ul>
              <li>
                <a target="_blank" href="https://loic-roger.fr">www.loic-roger.fr</a>
              </li>
              <li>
                <router-link :to="{name: 'aide'}">
                  <a>{{ $t('header.help') }}</a>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="copyright">
        <p>Copyright © 2020 Loïc Roger</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    data () {
      return {}
    }
  }
</script>

<style scoped lang="scss">
  @import "node_modules/bootstrap/scss/bootstrap";
  @import "../sass/app";

  .footer {
    background-color: $firstcolor;
    color: white;
    margin-top: 3rem;
    padding: 1rem;

    .titre_footer {
      text-transform: uppercase;
      font-family: $titlefont;
      font-weight: 900;
    }

    ul {
      padding: 0;
      list-style-type: none;
    }

    a {
      color: white;
    }

    a:hover {
      color: $secondcolor;
      text-decoration: none;
    }

    .col_right {
      text-align: right;
      @include media-breakpoint-down(xs) {
        text-align: left;
        margin-top: 2rem;
      }
    }

    .liens_footer {
      display: grid;
    }

    .div_logo {
      @include media-breakpoint-down(xs) {
        display: flex;
        a {
          margin: auto;
        }
      }
    }

    #copyright {
      text-align: center;
      margin-top: 2rem;
    }
  }
</style>
