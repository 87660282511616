<template>
  <div class="templateInscription">
    <div class="container inscription">
      <h1>Inscrivez-vous !</h1>
      <form @submit.prevent="inscription">
        <div class="row">

          <div class="col-sm-6 col-12">
            <div style="margin-left: 0; margin-right: 0;" class="form-group row">
              <input v-model="newUser.firstName" type="text" class="form-control" id="inputEmail" placeholder="Prénom">
            </div>
          </div>

          <div class="col-sm-6 col-12">
            <div style="margin-left: 0; margin-right: 0;" class="form-group row">
              <input v-model="newUser.lastName" type="text" class="form-control" id="inputEmail" placeholder="Nom">
            </div>
          </div>

          <div class="col-sm-6 col-12">
            <div style="margin-left: 0; margin-right: 0;" class="form-group row">
              <input v-model="newUser.email" type="email" class="form-control" id="inputEmail" placeholder="Email">
            </div>
          </div>

          <div class="col-sm-6 col-12">
            <div style="margin-left: 0; margin-right: 0;" class="form-group">
              <input v-model="newUser.password" type="password" class="form-control" id="inputPassword"
                     placeholder="Password">
            </div>
          </div>
        </div>


        <div style="display: flex" class="form-group row">
          <div style="margin: auto" class="col-sm-10">
            <button type="submit" class="liens btn">{{ $t('home.signUp') }}</button>
          </div>
        </div>
      </form>

      <h2>Vous vous êtes déjà inscrit ?<br/>{{ $t('connect.connectYou') }}</h2>
      <router-link :to="{ name: 'connexion' }">
        <button class="liens btn btn-lg">{{ $t('home.signIn') }} <i class="fas fa-arrow-right"></i></button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { UserInformations } from "@/data/UserInformations";

export default {
  name: "Inscription",

  data() {
    return {
      newUser: new UserInformations()
    }
  },

  methods: {
    async inscription() {
      try {
        await this.axios.post(`${process.env.VUE_APP_SERVER}api/auth/signup`, this.newUser);
        this.$toast.success("Inscription réussie");
        await this.$router.push('/');
      } catch (error) {
        this.$toast.error("Inscription échouée");
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../sass/app";

.templateInscription {
  padding: 5px;

  .image_log {
    margin-bottom: 1rem;
  }

  .inscription {
    background-color: $firstcolor;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
  }

  form {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
</style>
