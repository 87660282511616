<template>
  <div class="home">
    <h1 class="titrePrincipal">{{ $t('home') }}</h1>

    <div v-if="token === null" class="row connexion_inscription">
      <div class="col-sm-6 col-12 div_connexion">
        <h2>{{ $t('home.connectYou') }}</h2>
        <router-link :to="{name: 'connexion'}">
          <button class="liens btn btn-lg">{{ $t('home.signIn') }} <i class="fas fa-arrow-right"></i></button>
        </router-link>
      </div>
      <div class="col-sm-6 col-12 div_inscription">
        <h2>{{ $t('home.signUpYou') }}</h2>
        <router-link :to="{name: 'inscription'}">
          <button class="liens btn btn-lg">{{ $t('home.signUp') }} <i class="fas fa-arrow-right"></i></button>
        </router-link>
      </div>
    </div>

    <div class="divConnecte" v-if="token !== null">
      <h2 class="sousTitreConnecte">{{ $t('home.youAreConnected') }}</h2>
      <p class="explicationConnecte">{{ $t('home.hereForqrcode') }}</p>
      <router-link v-if="$store.state.userInformations.subscribe" :to="{name: 'generator'}">
        <b-btn class="accessQRCode">{{ $t('home.create.qrcode') }} <i class="fas fa-arrow-right"></i></b-btn>
      </router-link>
      <router-link v-else :to="{name: 'subscription'}">
        <b-btn class="accessQRCode">{{ $t('Souscrire') }} <i class="fas fa-arrow-right"></i></b-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
import {UserInformations} from "@/data/UserInformations";

export default {
  name: "Accueil",

  data() {
    return {
      user: new UserInformations()
    };
  },

  created() {
    const userInformations = this.$store.state.userInformations;
    this.user = new UserInformations(userInformations);
  },

  computed: {
    token() {
      return localStorage.getItem('accessToken');
    }
  },
}
</script>

<style scoped lang="scss">
@import "../sass/app";

.home {
  padding: 1rem;
  text-align: center;

  .titrePrincipal {
    margin-bottom: 3rem;
  }

  .connexion_inscription {
    background-color: $firstcolor;

    .div_connexion {
      @include media-breakpoint-down(sm) {
        padding: 1rem;
      }

      h2 {
        color: white;
      }

      padding: 5rem;
    }

    .div_inscription {
      @include media-breakpoint-down(sm) {
        padding: 1rem;
      }

      h2 {
        color: white;
      }

      padding: 5rem;
    }
  }

  .divConnecte {
    .sousTitreConnecte {
      color: $thirdcolor;
      font-family: $titlefont;
      margin-bottom: 1rem;
    }

    .explicationConnecte {
      font-family: $textfont;
      font-size: 18px;
      color: $annexecolor;
    }

    .accessQRCode {
      font-size: 25px;
      color: white;
      background-color: $firstcolor;
      border-radius: 50px;
      border: solid 2px $firstcolor;
      padding: 0.75rem;
      text-transform: uppercase;
    }

    .accessQRCode:hover {
      color: $firstcolor;
      background-color: white;
    }
  }
}
</style>
