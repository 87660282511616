<template>
  <div class="container">
    <form v-on:submit.prevent="save">
      <h2>{{ $t('profile.subtitle.infos') }}</h2>

      <b-row>
        <div class="col-md-6">
          <div>
            <input type="file" id="fileInput" @change="getImageURL" style="display: none;">
            <label for="fileInput" class="image-preview-container">
              <img v-if="imagePreview" :src="imagePreview" class="image-preview" alt="avatar">
              <img v-else-if="user.imageURL" :src="user.imageURL" class="image-preview" alt="Avatar">
              <span style="height: 100%;" v-else>
                <font-awesome-icon style="height: 100%;" icon="fa-regular fa-circle-user"/>
              </span>
              <span class="edit-text">Modifier</span>
            </label>
          </div>
        </div>
        <Field
            class="col-md-6"
            v-model="user.email"
            type="email"
            id="profileEmail"
            label="Email"
        />
        <Field
            class="col-md-6"
            v-model="user.firstName"
            type="text"
            id="profileFirstName"
            label="Prénom"
        />
        <Field
            class="col-md-6"
            v-model="user.lastName"
            type="text"
            id="profileLastName"
            label="Nom de famille"
        />
      </b-row>

      <h2>{{ $t('profile.subtitle.password') }}</h2>

      <b-row>
        <Field
            class="col-md-6"
            v-model="user.password"
            type="password"
            id="profilePassword"
            label="Mot de passe"
        />
        <Field
            class="col-md-6"
            v-model="confirmPassword"
            type="password"
            id="profileConfirmPassword"
            label="Confirmer votre mot de passe"
        />
      </b-row>
      <button type="submit" class="liens btn">{{ $t('common.save') }}</button>
    </form>
  </div>
</template>

<script>
import {UserInformations} from "../data/UserInformations";
import Field from "../components/Field.vue";
import heic2any from 'heic2any';

export default {
  name: "Profile",
  components: {Field},

  data() {
    return {
      imageComputer: null,
      imagePreview: null,
      confirmPassword: "",
      user: new UserInformations()
    };
  },

  created() {
    const userInformations = this.$store.state.userInformations;
    this.user = new UserInformations(userInformations);

    if (!this.user.imageURL) {
      this.user.imageURL = '';
    }
  },

  methods: {
    async getImageURL(event) {
      this.imageComputer = event.target.files[0];
      const file = event.target.files[0];

      if (file) {
        if (file.type === 'image/heic') {
          try {
            const result = await heic2any({blob: file, toType: 'image/jpeg'});
            this.imageComputer = new File([result], file.name.replace(/\.[^/.]+$/, '.jpeg'), {type: 'image/jpeg'});
          } catch (error) {
            console.error('Error converting HEIC to JPEG:', error);
            this.$toast.error("Conversion d'image échouée");
            return;
          }
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(this.imageComputer);
      }
    },

    save() {
      const formData = new FormData();
      formData.append('_id', this.user._id);
      formData.append('email', this.user.email || '');
      formData.append('password', this.user.password || '');
      formData.append('firstName', this.user.firstName || '');
      formData.append('lastName', this.user.lastName || '');
      formData.append('subscribe', this.user.subscribe || false);

      if (this.imageComputer) {
        formData.append('imageURL', this.imageComputer);
      }

      this.axios.put(process.env.VUE_APP_SERVER + 'api/auth/user', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.$toast.success("Utilisateur mis à jour avec succès !");
      }).catch(error => {
        console.error(error);
        this.$toast.error("Erreur interne : " + error);
      });
    }
  }
};
</script>

<style scoped>
.image-preview-container {
  border-radius: 50px;
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image-preview-container:hover .image-preview {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}

.edit-text {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-preview-container:hover .edit-text {
  opacity: 1;
}
</style>