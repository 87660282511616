<template>
  <div class="main">
    <Header :userInformations="userInformations"/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import {UserInformations} from "@/data/UserInformations";

export default {
  name: 'App',
  components: { Footer, Header },
  data() {
    return {
      userInformations: new UserInformations()
    };
  },
  created() {
    const token = localStorage.getItem('accessToken');
    if (token) {
      this.axios.get(process.env.VUE_APP_SERVER + 'api/auth/user', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        console.log(response.data)
        this.$store.dispatch('loadUserInfo', response.data);
        this.userInformations = new UserInformations(response.data);
      }).catch((err) => {
        console.error(err);
      });
    }
  }
};
</script>

<style>
</style>