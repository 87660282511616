import VueRouter from "vue-router";
import Accueil from "@/pages/Accueil";
import Generator from "@/pages/GenerateurQRCode";
import Aide from "@/pages/Aide";
import Error404 from "@/pages/Error404";
import Inscription from "@/pages/Inscription";
import Connexion from "@/pages/Connexion";
import Profile from "@/pages/Profile.vue";
import Subscription from "@/pages/Subscription.vue";

const routes = [
    {
        path: '/',
        name: 'accueil',
        component: Accueil
    },
    {
        path: '/creer-mon-qrcode',
        name: 'generator',
        component: Generator
    },
    {
        path: '/aide',
        name: 'aide',
        component: Aide
    },
    {
        path: '/inscription',
        name: 'inscription',
        component: Inscription
    },
    {
        path: '/connexion',
        name: 'connexion',
        component: Connexion
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile
    },
    {
        path: '/subscription',
        name: 'subscription',
        component: Subscription
    },
    {
        path: '*',
        name: 'error',
        component: Error404
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
