<template>
  <div class="templateConnexion">
    <div class="container connexion">
      <h1>{{ $t('connect.connectYou') }}</h1>
      <form v-on:submit.prevent="connection">
        <div class="row">
          <div class="col-sm-6 col-12">
            <div style="margin-left: 0; margin-right: 0;" class="form-group row">
              <input v-model="email" type="email" class="form-control" id="inputEmail" placeholder="Email">
            </div>
          </div>

          <div class="col-sm-6 col-12">
            <div style="margin-left: 0; margin-right: 0;" class="form-group">
              <input v-model="password" type="password" class="form-control" id="inputPassword"
                     placeholder="Mot de passe">
            </div>
          </div>
        </div>

        <div style="display: flex" class="form-group row">
          <div style="margin: auto" class="col-sm-10">
            <button type="submit" class="liens btn">{{ $t('home.signIn') }}</button>
          </div>
        </div>
      </form>

      <div class="btn_log_social row">
        <div class="col-sm-6 col-12">
          <a href="http://localhost:8000/users/auth/google">
            <img class="google_btn" src="../assets/google.png" alt="google-connexion">
          </a>
        </div>

        <div class="col-sm-6 col-12">
          <a href="http://localhost:8000/auth/facebook">
            <img class="facebook_btn" src="../assets/facebook.png" alt="facebook-connexion">
          </a>
        </div>
      </div>

      <h2>{{ $t('connect.notAccount') }}<br/></h2>
      <router-link :to="{name: 'inscription'}">
        <button class="liens btn btn-lg">{{ $t('connect.signUpYou') }} <i class="fas fa-arrow-right"></i></button>
      </router-link>
    </div>
  </div>
</template>

<script>
import router from "../../config/router";

export default {
  name: "connexion",

  data() {
    return {
      email: null,
      password: null,
    }
  },

  methods: {
    connection: function () {
      this.axios.post(process.env.VUE_APP_SERVER + 'api/auth/login', {
        email: this.email,
        password: this.password,
      })
          .then(response => {
            localStorage.setItem('accessToken', response.data.token);
            localStorage.setItem('userId', response.data.user._id);
            this.$store.dispatch('loadUserInfo', response.data.user)
            router.push('/');
            location.reload()
            this.$toast.success("Bienvenue " + response.data.user.firstName);
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$toast.error("Identifiants ou mot de passe incorrect");
            } else {
              this.$toast.error("Connexion échouée");
            }
          })
    }
  },
  mounted() {
    //this.$toast.success("I'm a toast!");
  }
}
</script>

<style scoped lang="scss">
@import "../sass/app";

.templateConnexion {
  padding: 5px;

  .image_log {
    margin-bottom: 1rem;
  }

  .connexion {
    background-color: $firstcolor;
    text-align: center;
    padding: 10px;
    border-radius: 5px;

    .btn_log_social {
      .google_btn, .facebook_btn {
        width: 40%;
        margin-bottom: 1rem;
      }
    }
  }

  form {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
</style>
