import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import BootstrapVue from 'bootstrap-vue'

import Vue from 'vue';
import VueRouter from "vue-router";
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(VueAxios, axios);
Vue.use(Toast);
