<template>
  <div class="accueil">
    <div class="pasConnecte" v-if="token === null">
      <h1 class="titreSeConnecter">{{ $t('generator.connectYouForqrcode') }}</h1>
      <b-btn class="btnSeConnecter">{{ $t('home.signIn') }}</b-btn>
    </div>

    <div class="row" v-if="token !== null">
      <div class="col-md-3 col-12">
        <h2 class="titre">{{ $t('generator.title.qrcode') }}</h2>
        <b-form-group class="input" :label="$t('generator.label.link')">
          <b-form-input v-model="url" placeholder="https://loic-roger.fr" @input="generateQRCode"></b-form-input>
        </b-form-group>

        <b-form-group class="input" :label="$t('generator.label.backgroundColor')">
          <b-form-input v-model="bgColor" type="color" @input="generateQRCode"></b-form-input>
        </b-form-group>

        <b-form-group class="input" :label="$t('generator.label.color')">
          <b-form-input v-model="color" type="color" @input="generateQRCode"></b-form-input>
        </b-form-group>

        <h2 class="titre">{{ $t('generator.title.border') }}</h2>
        <b-form-group class="input" :label="$t('generator.label.border.weight')">
          <b-form-spinbutton v-model="bordurePx" min="0" max="20" @input="styleQRCode"></b-form-spinbutton>
        </b-form-group>

        <b-form-group class="input" :label="$t('generator.label.border.style')">
          <b-form-input v-model="bordureBody" :placeholder="$t('generator.label.border.style')" @input="styleQRCode"></b-form-input>
        </b-form-group>

        <b-form-group class="input" :label="$t('generator.label.border.color')">
          <b-form-input v-model="bordureColor" type="color" @input="styleQRCode"></b-form-input>
        </b-form-group>

        <b-form-group class="input" :label="$t('generator.label.border.radius')">
          <b-form-spinbutton v-model="bordureRadius" min="0" max="50" @input="styleQRCode"></b-form-spinbutton>
        </b-form-group>

        <b-form-group class="input" :label="$t('generator.label.border.space')">
          <b-form-spinbutton v-model="bordurePadding" min="0" max="20" @input="styleQRCode"></b-form-spinbutton>
        </b-form-group>
      </div>

      <div class="col-md-6 col-12">
        <h1>{{ $t('generator.qrcode') }}</h1>
        <div class="div_qrcode">
          <div id="qrcode"></div>
        </div>
      </div>

      <div class="col-md-3 col-12">
        <h2 class="titre">{{ $t('generator.title.image') }}</h2>
        <b-form-group>
          <b-form-file @change="generateImage"></b-form-file>
        </b-form-group>
        <div v-if="this.imageUpload === true">
          <h4>{{ $t('generator.image.size') }}<br/>
            <span id="tailleImgDesc">{{ $t('generator.image.warning') }}</span>
          </h4>
          <b-form-group class="input" :label="$t('generator.label.image.length')">
            <b-form-spinbutton v-model="width" min="2" max="40" @input="generateQRCode"></b-form-spinbutton>
          </b-form-group>

          <b-form-group class="input" :label="$t('generator.label.image.weight')">
            <b-form-spinbutton v-model="height" min="2" max="40" @input="generateQRCode"></b-form-spinbutton>
          </b-form-group>

          <h4>{{ $t('generator.title.image.position') }}</h4>
          <b-form-group class="input" :label="$t('generator.label.image.positionX')">
            <b-form-spinbutton v-model="posX" min="0" max="65" @input="generateQRCode"></b-form-spinbutton>
          </b-form-group>

          <b-form-group class="input" :label="$t('generator.label.image.positionY')">
            <b-form-spinbutton v-model="posY" min="0" max="65" @input="generateQRCode"></b-form-spinbutton>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {QRCodeSVG} from '@cheprasov/qrcode';

let stack;
const reader = new FileReader();

reader.addEventListener("load", function () {
  stack = reader.result;
}, false);

export default {
  name: 'Accueil',
  data() {
    return {
      url: '',
      bgColor: '#ffffff',
      color: '#000000',

      imageUpload: false,
      width: 30,
      height: 30,
      posX: 35,
      posY: 35,

      bordurePx: '',
      bordureBody: 'solid',
      bordureColor: '#000000',
      bordureRadius: '',
      bordurePadding: ''
    }
  },
  computed: {
    token() {
      return localStorage.getItem('accessToken');
    }
  },
  methods: {
    styleQRCode: function () {
      const qrcode = document.getElementById('qrcode');
      qrcode.style.border = this.bordurePx + "px" + " " + this.bordureBody + " " + this.bordureColor
      qrcode.style.borderRadius = this.bordureRadius + 'px'
      qrcode.style.padding = this.bordurePadding + 'px'
    },

    generateImage: function (event) {
      reader.readAsDataURL(event.target.files[0]);
      setTimeout(this.generateQRCode, 50)
    },

    generateQRCode: function () {
      const divElement = document.getElementById('qrcode');

      if (stack) {
        this.imageUpload = true
      }

      if (this.url.length > 0) {
        let options = {
          level: 'H',
          fgColor: this.color,
          bgColor: this.bgColor
        }
        if (stack) {
          Object.assign(options, {
            image: {
              source: stack,
              width: this.width + '%',
              height: this.height + '%',
              x: this.posX + '%',
              y: this.posY + '%',
            }
          })
        }
        const qrSVG = new QRCodeSVG(this.url, options);
        divElement.innerHTML = qrSVG.toString();
      } else {
        divElement.innerHTML = '';
      }
    }
  },

  mounted() {
    this.styleQRCode();
  }
}
</script>

<style lang="scss">
@import "../sass/app";

.accueil {
  margin: 0 1rem;
}

h1 {
  text-align: center;
  font-family: $titlefont;
  color: $firstcolor;
  font-size: 50px;
  margin-bottom: 5rem;
}

.titre {
  text-align: center;
  margin-top: 5rem;
  color: $thirdcolor;
  font-family: $titlefont;
}

.input {
  margin: 1rem 0;
}

.div_qrcode {
  margin-top: 4rem;
  #qrcode {
    margin: auto;
    width: 500px;
    height: 500px;
  }
  #qrcode {
    @include media-breakpoint-down(xs) {
      width: 300px;
      height: 300px;
    }
  }
}

.pasConnecte {
  text-align: center;
  .titreSeConnecter {
    color: $thirdcolor;
    font-family: $titlefont;
    margin-bottom: 3rem;
  }

  .btnSeConnecter {
    font-size: 25px;
    color: white;
    background-color: $firstcolor;
    border-radius: 50px;
    border: solid 2px $firstcolor;
    padding: 0.75rem;
    text-transform: uppercase;
    margin-bottom: 3rem;
  }

  .btnSeConnecter:hover {
    color: $firstcolor;
    background-color: white;
  }
}
</style>
