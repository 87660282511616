<template>
  <div class="header">
    <div class="container">
      <router-link :to="{name:'accueil'}">
        <img height="100" src="../assets/logo.png" alt="logo"/>
      </router-link>

      <div class="liste_btn ml-auto">
<!--        <router-link class="menu-link" v-if="this.$route.name !== 'aide'" :to="{name:'aide'}">
          <button class="btn-header btn form-inline my-2 my-lg-0">{{ $t('header.help') }}</button>
        </router-link>

        <router-link class="menu-link" v-if="this.$route.name !== 'accueil'" :to="{name:'accueil'}">
          <button class="btn-header btn form-inline my-2 my-lg-0">{{ $t('home') }}</button>
        </router-link>-->

        <div v-if="token" style="margin: auto;">
          <b-button class="sidebar-prefs" v-b-toggle.sidebar-prefs>
            <div class="avatar-header-container">
              <img :src="userInformations.imageURL" class="avatar-header" alt="Avatar">
            </div>
            <span class="name-sidebar">{{ userInformations.firstName }} {{ userInformations.lastName }}</span>
            <span class="icon-sidebar"><i class="fas fa-bars"></i></span>
          </b-button>
          <!-- TODO: Ajouter les icônes -->
          <b-sidebar id="sidebar-prefs" title="Mes préférences" backdrop right shadow>
            <div class="sidebar-menu-buttons">
              <router-link :to="{name:'aide'}">
                <b-button class="button-sidebar">
                  {{ $t('header.help') }}
                </b-button>
              </router-link>
              <router-link :to="{name:'profile'}">
                <b-button class="button-sidebar">
                  {{ $t('header.sidebar.profile') }}
                </b-button>
              </router-link>
              <router-link :to="{name:'subscription'}">
                <b-button class="button-sidebar">
                  {{ $t('header.sidebar.subscription') }}
                </b-button>
              </router-link>
              <b-button class="button-sidebar">
                {{ $t('header.sidebar.language') }} : {{$i18n.locale}}
              </b-button>
            </div>
            <div class="px-3 py-2">
              <button v-on:click="logout"
                      class="btn-header_deconnexion btn form-inline my-2 my-lg-0">
                <i class="fas fa-sign-out-alt"></i> {{ $t('header.sidebar.logout') }}
              </button>
            </div>
          </b-sidebar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../../config/router";
import {UserInformations} from "@/data/UserInformations";

export default {
  name: 'Header',
  props: {
    userInformations: UserInformations
  },

  methods: {
    logout: function () {
      this.userInformations = null
      localStorage.clear();
      router.push('/connexion');
      location.reload();
    }
  },
  computed: {
    token() {
      return localStorage.getItem('accessToken');
    }
  }
}
</script>

<style lang="scss">
@import "../sass/app";

.header {
  margin-bottom: 3rem;
  background-color: $firstcolor;

  .container {
    display: flex;
  }

  .liste_btn {
    margin: auto 0 auto auto;
    display: flex;

    .menu-link {
      margin: auto;

      .btn-header {
        color: white;
        font-size: 14px;
        padding: 5px;
        text-transform: uppercase;
        border-radius: 0;
        height: 38px;
        margin-right: 20px;
      }
      .btn-header:hover {
        border-bottom-color: white;
        transition: border-bottom-color 400ms ease;
      }
      .btn-header:active, .btn-header:focus {
        box-shadow: none;
      }
    }
  }

  .sidebar-prefs {
    display: flex;
    margin-left: 5px;
    border-radius: 25px;
    background-color: $firstcolor !important;
    border-color: white !important;

    .icon-sidebar, .name-sidebar {
      margin: auto auto auto 10px;
    }

    .avatar-header-container {
      border-radius: 50px;
      width: 40px;
      height: 40px;
      overflow: hidden; /* Masquer les parties de l'image qui débordent du conteneur */
      position: relative; /* Positionner l'image à l'intérieur du conteneur */
    }
    .avatar-header {
      width: 100%;
      height: 100%;
      object-fit: cover; /* Rogner l'image pour qu'elle remplisse le conteneur sans être déformée */
      object-position: center; /* Centrer l'image dans le conteneur */
    }
  }

  .sidebar-prefs:hover, .sidebar-prefs:active, .sidebar-prefs:focus {
    box-shadow: rgba(255, 255, 255, 0.5) 0 0 0 3px !important;
  }

  .sidebar-menu-buttons {
    display: grid;

    .button-sidebar, .button-sidebar:hover, .button-sidebar:active, .button-sidebar:focus {
      text-align: left;
      color: $black !important;
      background-color: transparent !important;
      border: none;
      box-shadow: none !important;
    }
  }
}
</style>
