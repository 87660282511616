import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        plan: {},
        token: localStorage.getItem('token'),
        userInformations: {},
        isLoading: false
    },
    mutations: {
        SET_USER_INFO(state, userInfo) {
            state.userInformations = userInfo;
        }
    },
    actions: {
        loadUserInfo({ commit }, userInfo) {
            commit('SET_USER_INFO', userInfo);
        },
        toggleLoading(state) {
            state.isLoading = !state.isLoading;
        }
    },
    getters: {
        plan: state => state.plan,
        isLoading: state => state.isLoading,
        userInformations: state => state.userInformations
    }
});