export class UserInformations {
    constructor({ _id = '', email = '', firstName = '', lastName = '', subscribe = '', imageURL = '', stripeCustomerId = '' } = {}) {
        this._id = _id;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.subscribe = subscribe;
        this.imageURL = imageURL;
        this.stripeCustomerId = stripeCustomerId;
    }
}