import fr from './fr.json'
import en from './en.json'
import es from './es.json'
import de from './de.json'
import it from './it.json'
import VueI18n from 'vue-i18n'
import Vue from'vue'

export const defaultLocale = 'fr'

export const languages = {
    fr: fr,
    en: en,
    es: es,
    de: de,
    it: it
}

Vue.use(VueI18n)

const messages = Object.assign(languages)

export default new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'fr',
    messages
})