<template>
  <div class="container">
    <h2>{{ $t('subscription.title') }}</h2>
    <div v-if="user.subscribe">
      Votre abonnement est actif
    </div>
    <div v-else>
      <form @submit.prevent="handleSubscription">
        <!-- Card number field -->
        <div class="form-group">
          <label for="card-number">Numéro de carte</label>
          <div id="card-number" class="card-element"></div>
        </div>

        <!-- Expiry date field -->
        <div class="form-group">
          <label for="card-expiry">Date d'expiration</label>
          <div id="card-expiry" class="card-element"></div>
        </div>

        <!-- CVC field -->
        <div class="form-group">
          <label for="card-cvc">Code de sécurité</label>
          <div id="card-cvc" class="card-element"></div>
        </div>

        <button type="submit" class="payment-button">Souscrire</button>
      </form>
    </div>
  </div>
</template>

<script>
import { UserInformations } from "../data/UserInformations";
import { loadStripe } from "@stripe/stripe-js";
import {mapState} from "vuex";

export default {
  name: "Subscription",

  data() {
    return {
      stripe: null,
      elements: null,
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCvcElement: null,
      clientSecret: null,
    };
  },

  computed: {
    ...mapState({
      user: state => new UserInformations(state.userInformations)
    })
  },

  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
    this.elements = this.stripe.elements();

    // Card number element
    this.cardNumberElement = this.elements.create('cardNumber');
    this.cardNumberElement.mount('#card-number');

    // Expiry date element
    this.cardExpiryElement = this.elements.create('cardExpiry');
    this.cardExpiryElement.mount('#card-expiry');

    // CVC element
    this.cardCvcElement = this.elements.create('cardCvc');
    this.cardCvcElement.mount('#card-cvc');
  },

  methods: {
    async handleSubscription() {
      try {
        // Vérifiez si l'utilisateur a déjà un client Stripe
        if (!this.user.stripeCustomerId) {
          // Créez un client Stripe si nécessaire
          const stripeResponse = await this.axios.post(process.env.VUE_APP_SERVER + 'api/stripe/create-customer', {
            email: this.user.email,
            name: this.user.lastName + ' ' + this.user.firstName,
            userId: this.user._id
          });

          this.user.stripeCustomerId = stripeResponse.data.customerId;
        }

        // Créez l'abonnement en utilisant l'ID du client Stripe
        const response = await this.axios.post(process.env.VUE_APP_SERVER + 'api/stripe/create-subscription', {
          customerId: this.user.stripeCustomerId,
          priceId: process.env.VUE_APP_STRIPE_SUBSCRIPTION_HEBDO,
          userId: this.user._id
        });

        this.clientSecret = response.data.clientSecret;

        const result = await this.stripe.confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.cardNumberElement,
            billing_details: {
              name: this.user.lastName + ' ' + this.user.firstName,
            },
          },
        });

        if (result.error) {
          console.error(result.error.message);
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            this.$toast.success('Vous êtes mainenant abonné à notre service !');
          }
        }
      } catch (error) {
        console.error(error);
        this.$toast.error('Échec de la souscription : ' + error.message);
      }
    }
  }
};
</script>

<style scoped>
/* Styles for the form and its components */
.container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

/* Styles for the Stripe Elements */
.card-element {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
}

.card-element:focus {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

/* Styles for the payment button */
.payment-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #3490dc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-button:hover {
  background-color: #2779bd;
}
</style>
