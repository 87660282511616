<template>
  <b-container>
    <h1>{{ $t('header.help') }}</h1>

    <h2 class="titrePartie">{{ $t('generator.title.qrcode') }}</h2>
    <h3 class="sousTitrePartie">{{ $t('generator.label.link') }}</h3>
    <h4 class="description">{{ $t('help.description.link') }}</h4>

    <h3 class="sousTitrePartie">{{ $t('generator.label.backgroundColor') }}</h3>
    <h4 class="description">{{ $t('help.description.backgroundColor') }}</h4>

    <h3 class="sousTitrePartie">{{ $t('generator.label.color') }}</h3>
    <h4 class="description">{{ $t('help.description.color') }}</h4>

    <h2 class="titrePartie">{{ $t('generator.title.border') }}</h2>
    <h4 id="descriptionBordure" class="description">{{ $t('generator.description.border') }}</h4>

    <h3 class="sousTitrePartie">{{ $t('generator.label.border.weight') }}</h3>
    <h4 class="description">{{ $t('generator.description.weight') }}</h4>

    <h3 class="sousTitrePartie">{{ $t('generator.label.border.style') }}</h3>
    <h4 class="description">{{ $t('generator.description.style') }}
      <ul>
        <li>{{ $t('help.description.border.style.solid') }}</li>
        <li>{{ $t('help.description.border.style.dotted') }}</li>
        <li>{{ $t('help.description.border.style.dashed') }}</li>
        <li>{{ $t('help.description.border.style.double') }}</li>
        <li>{{ $t('help.description.border.style.groove') }}</li>
        <li>{{ $t('help.description.border.style.ridge') }}</li>
        <li>{{ $t('help.description.border.style.inset') }}</li>
        <li>{{ $t('help.description.border.style.outset') }}</li>
      </ul>
      {{ $t('help.description.border.style.default') }}
    </h4>

    <h3 class="sousTitrePartie">{{ $t('generator.label.border.color') }}</h3>
    <h4 class="description">{{ $t('help.description.border.color') }}</h4>

    <h3 class="sousTitrePartie">{{ $t('generator.label.border.radius') }}</h3>
    <h4 class="description">{{ $t('help.description.border.radius') }}</h4>

    <h3 class="sousTitrePartie">{{ $t('generator.label.border.space') }}</h3>
    <h4 class="description">{{ $t('help.description.border.space') }}</h4>

    <h2 class="titrePartie">{{ $t('generator.title.image') }}</h2>
    <h3 class="sousTitrePartie">{{ $t('help.title.image') }}</h3>
    <h4 class="description">{{ $t('help.description.image.choice') }}</h4>

    <h3 class="sousTitrePartie">{{ $t('generator.image.size') }}</h3>
    <h4 class="description">{{ $t('help.description.image.size') }}<br/>
      <span class="attentionImage">{{ $t('help.description.image.size.warning') }}</span>
    </h4>

    <h3 class="sousTitrePartie">{{ $t('help.title.image.position') }}</h3>
    <h4 class="description">{{ $t('help.description.image.position') }}</h4>
  </b-container>
</template>

<script>
export default {
  name: "Aide"
}
</script>

<style scoped lang="scss">
@import "../sass/app";

.titrePartie {
  color: $thirdcolor;
  font-family: $titlefont;
  margin-top: 5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.sousTitrePartie {
  color: $annexecolor;
  font-family: $titlefont;
  text-decoration: underline;
}

.description {
  font-family: $textfont;
  color: $annexecolor;
  font-size: 18px;
  margin-bottom: 2rem;
  font-weight: normal;
  .attentionImage {
    color: $danger;
    font-style: italic;
    font-weight: bold;
  }
}

#descriptionBordure {
  text-align: center;
  font-size: 20px;
}

</style>
