import Vue from 'vue';
import App from './App.vue';
import router from '../config/router';
import {store} from '../src/store/index';
import axios from 'axios';
import '../config/init';
import i18n from "@/i18n/index.ts";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { } from '@fortawesome/free-solid-svg-icons'
import { faCircleUser } from '@fortawesome/free-regular-svg-icons'

library.add(faCircleUser)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;

// Corrige les problèmes CORS
axios.defaults.withCredentials = true;
// Configurer Axios pour inclure le jeton JWT avec chaque requête sortante
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        console.error('Request interceptor error:', error);
        return Promise.reject(error);
    }
);

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
